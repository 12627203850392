<template>
  <div>
    <b-card class="mb-1">
      <div class="d-flex flex-wrap justify-content-between">
        <h2>{{ $t('detais_user.title') }}</h2>
        <div
          v-if="
            !isFetchingUserDetails && itemDetails && connected_user_id != id
          "
          class="d-flex flex-wrap"
        >
          <!-- mail recup password -->
          <div>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              v-b-modal.modal-center
              class="mr-2"
              variant="primary"
              @click="sendRecupPasswordMail()"
              :disabled="isLoadingTreatment || isLoadingSendResetPasswordMail"
            >
              <feather-icon icon="SendIcon" />
              {{ $t('detais_user.send_mail_recovery_password') }}
              <b-spinner v-if="isLoadingSendResetPasswordMail" small />
            </b-button>
          </div>
          <!-- validate email -->
          <div class="res" v-if="!itemDetails.email_verified_at">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              v-b-modal.modal-center
              class="mr-2"
              variant="primary"
              :disabled="isLoadingTreatment"
              @click="
                () => {
                  updateAccount('email_verified_at', this.formattedDate)
                }
              "
            >
              <i class="las la-check"></i>
              {{ $t('detais_user.validate_email') }}
              <b-spinner v-if="isLoadingTreatment" small />
            </b-button>
          </div>
          <!-- send code OPT -->
          <!-- <div class="res" v-if="true">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              v-b-modal.modal-center
              class="mr-2"
              variant="primary"
              :disabled="isLoadingSendingOTP"
              @click="
                () => {
                  send_otp();
                }
              "
            >
              <i class="las la-shield-alt"></i>
              Envoyer un code OTP
              <b-spinner v-if="isLoadingSendingOTP" small />
            </b-button>
          </div> -->
          <!-- active account -->
          <div class="res">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              v-b-modal.modal-center
              class="mr-2"
              :variant="itemDetails.is_active ? 'danger' : 'primary'"
              :disabled="isLoadingTreatment"
              @click="
                () => {
                  updateAccount('is_active', !itemDetails.is_active)
                }
              "
            >
              <i
                class="las"
                :class="itemDetails.is_active ? 'la-times' : 'la-check-circle'"
              />
              {{
                itemDetails && itemDetails.is_active
                  ? $t('detais_user.unactivate_account')
                  : $t('detais_user.activate_account')
              }}
              <b-spinner v-if="isLoadingTreatment" small />
            </b-button>
          </div>
          <!-- button -->
          <div class="res">
            <ButtonArchiverUtilisateur
              v-if="itemDetails"
              :utilisateur-id="itemDetails.id"
              redirect-route="users-list"
            />
          </div>
        </div>
      </div>
    </b-card>
    <h1 v-if="!isFetchingUserDetails && !itemDetails">
      Aucun utilisateur ne correspond à cet enregistrement
    </h1>
    <content-loader
      v-if="isFetchingUserDetails"
      view-box="0 0 400 460"
      :speed="2"
      primary-color="#f3f3f3"
      secondary-color="#ecebeb"
    >
      <circle cx="29" cy="30" r="17" />
      <rect x="58" y="18" rx="2" ry="2" width="140" height="10" />
      <rect x="58" y="34" rx="2" ry="2" width="140" height="10" />
      <rect x="-7" y="60" rx="2" ry="2" width="408" height="132" />
    </content-loader>
    <b-card v-if="itemDetails" class="mb-0">
      <div class="row">
        <!-- roles -->
        <div class="col-md-12 d-flex justify-content-end mb-3">
          <!-- class="vue-horizontal"
            style="overflow-x: auto; max-width: 500px" -->
          <span v-for="(role, index_r) in itemDetails.roles" :key="index_r">
            <b-badge variant="" :class="index_r > 0 && 'ml-1'">
              <i
                class="las la-times mr-25"
                v-b-tooltip.hover.top="$t('detais_user.remove_role')"
                @click="showRemoveRole(role)"
              />
              <span>{{ role.title }}</span>
            </b-badge>
          </span>
          <span
            class="addRole"
            v-if="
              tableDataRole.length != 0 &&
              itemDetails.roles[0].id != 7 &&
              itemDetails.roles[0].id != 5 &&
              itemDetails.roles[0].id != 8 &&
              itemDetails.roles[0].id != 9 &&
              itemDetails.roles[0].id != 10 &&
              itemDetails.roles[0].id != 5
            "
          >
            <b-badge
              variant="light-primary"
              class="ml-1 cursor-pointer"
              v-b-tooltip.hover.top="$t('detais_user.add_role')"
              @click="showAddRole()"
            >
              <i class="las la-plus mr-25" />
              <span> {{ $t('detais_user.add_role') }}</span>
            </b-badge>
          </span>
        </div>
        <hr />
        <!-- infos -->
        <div class="col-md-4">
          <p
            v-if="
              tableDataRole.length != 0 &&
              itemDetails.roles.map((r) => r.alias.toString()) == 'AUAT'
            "
          >
            <strong>{{ $t('detais_user.label_lastname') }} : </strong
            ><b-form-input
              type="text"
              v-model="itemDetails.lastname"
              @change="updateAccount('lastname', itemDetails.lastname)"
            />
          </p>
          <p v-else>
            <strong>{{ $t('detais_user.label_lastname') }} : </strong
            >{{ itemDetails && itemDetails.lastname }}
          </p>
        </div>
        <div class="col-md-4">
          <p
            v-if="
              tableDataRole.length != 0 &&
              itemDetails.roles.map((r) => r.alias.toString()) == 'AUAT'
            "
          >
            <strong>{{ $t('detais_user.label_firstname') }} : </strong
            ><b-form-input
              type="text"
              v-model="itemDetails.firstname"
              @change="updateAccount('firstname', itemDetails.firstname)"
            />
          </p>
          <p v-else>
            <strong>{{ $t('detais_user.label_firstname') }} : </strong
            >{{ itemDetails && itemDetails.firstname }}
          </p>
        </div>
        <div
          class="col-md-4"
          v-if="itemDetails.profile && itemDetails.profile.email_promoteur"
        >
          <p>
            <strong>{{ $t('detais_user.label_email_promotor') }} : </strong
            >{{
              itemDetails &&
              itemDetails.profile &&
              itemDetails.profile.email_promoteur
            }}
          </p>
        </div>

        <hr class="mt-1" v-if="itemDetails.profile" />
        <div class="col-md-12 row mt-1" v-if="itemDetails.profile">
          <div class="col-md-4">
            <p>
              <strong
                >{{ $t('detais_user.label_establishment_name') }} : </strong
              >{{
                itemDetails &&
                itemDetails.profile &&
                itemDetails.profile.nom_etablissement
              }}
            </p>
          </div>
          <div class="col-md-4" v-if="itemDetails.email">
            <p>
              <strong
                >{{ $t('detais_user.label_establishment_email') }} : </strong
              >{{ itemDetails && itemDetails.email }}
            </p>
          </div>
          <div class="col-md-4" v-if="itemDetails.profile">
            <p>
              <strong
                >{{ $t('detais_user.label_establishment_adress') }} : </strong
              >{{
                itemDetails &&
                itemDetails.profile &&
                itemDetails.profile.adresse_etablissement
              }}
            </p>
          </div>
        </div>
      </div>
    </b-card>
    <!-- Modals for role --------------------------------------------- -->
    <!-- ajout -->
    <b-modal
      :ref="addRole"
      :title="$t('detais_user.add_role_card_tilte')"
      ok-only
      ok-title=""
      ok-variant=""
      no-close-on-backdrop
    >
      <b-card-text class="row">
        <span class="col-md-12">
          {{ $t('detais_user.add_role_card_label') }}
        </span>
        <span class="col-md-12">
          <b-input-group class="w-100">
            <v-select
              class="w-100"
              v-model="new_role_id"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              label="title"
              :reduce="(option) => option.id"
              :options="tableDataRole"
              :placeholder="$t('detais_user.add_role_card_placeholder')"
            />
          </b-input-group>
        </span>
      </b-card-text>
      <template #modal-footer>
        <div class="">
          <b-button
            variant="danger"
            @click="on_add_role()"
            :disabled="!new_role_id"
          >
            <b-spinner v-if="new_role_loading" small class="mr-25" />
            <i class="las la-plus mr-25" v-else />
            <span>{{ $t('detais_user.add_role_card_button') }}</span>
          </b-button>
        </div>
      </template>
    </b-modal>
    <!-- retrait -->
    <b-modal
      :ref="removeRole"
      :title="$t('detais_user.remove_role_card_tilte')"
      ok-only
      ok-title=""
      ok-variant=""
    >
      <b-card-text>
        {{ $t('detais_user.remove_role_card_msg') }}
        <br />
        <strong class="mt-1 text-center">{{
          remove_role && remove_role.title
        }}</strong>
      </b-card-text>
      <template #modal-footer>
        <div class="">
          <b-button variant="danger" @click="on_remove_role()">
            <b-spinner v-if="remove_role_loading" small class="mr-25" />
            <i class="las la-times mr-25" v-else />
            <span> {{ $t('detais_user.remove_role_card_button') }}</span>
          </b-button>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BLink,
  BModal,
  BCardTitle,
  BCardText,
  BForm,
  BButton,
  BFormInput,
  BFormGroup,
  BInputGroup,
  BInputGroupAppend,
  BFormCheckbox,
  BFormFile,
  BSpinner,
  BBadge,
  BTable,
  VBTooltip,
  VBModal,
} from 'bootstrap-vue'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import useVuelidate from '@vuelidate/core'
import { mapActions } from 'vuex'
import vSelect from 'vue-select'
import AppLogo from '@/components/AppLogo.vue'
import RecaptchaWidget from '@/components/RecaptchaWidget.vue'
import { ContentLoader } from 'vue-content-loader'
// import ButtonArchiver from '@/views/apps/utilisateurs/operateurs/agents/components/ButtonArchiver.vue'
import Ripple from 'vue-ripple-directive'
// import dayjs from "dayjs";
import utilsService from '@/services/utils/utils.service'
// store module and vuex utilities
import etablissementsStroreModule from '@/store/etablissements'
import paramsStoreModule from '@/store/params'
import usersStoreModule from '@/store/users'
import rolesStoreModule from '@/store/roles'
import {
  registerStoreModule,
  unregisterStoreModule,
} from '@/helpers/vuex-utils'
import ButtonArchiverUtilisateur from '@/views/apps/utilisateurs/administration/component/ButtonArchiverUtilisateur.vue'
import { map } from 'leaflet'

export default {
  setup() {
    const requiredStoreModules = [
      { path: 'etablissements', module: etablissementsStroreModule },
      { path: 'params', module: paramsStoreModule },
      { path: 'users', module: usersStoreModule },
      { path: 'roles', module: rolesStoreModule },
    ]
    // Register module
    registerStoreModule(requiredStoreModules)

    return { requiredStoreModules, $v: useVuelidate({ $lazy: true }) }
  },
  components: {
    BCard,
    BModal,
    BLink,
    BCardTitle,
    BCardText,
    BForm,
    BButton,
    BFormInput,
    BFormGroup,
    BInputGroup,
    BInputGroupAppend,
    BFormCheckbox,
    BFormFile,
    BBadge,
    BTable,

    // validations
    AppLogo,
    vSelect,
    // eslint-disable-next-line vue/no-unused-components
    RecaptchaWidget,
    BSpinner,
    BRow,
    BCol,
    ContentLoader,
    // ListAgentPoliceComponent,
    ButtonArchiverUtilisateur,
  },

  directives: {
    'b-tooltip': VBTooltip,
    'b-modal': VBModal,
    Ripple,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      id: null,
      itemDetails: null,
      forms: [],
      isFetchingUserDetails: false,
      isLoadingTableData: false,
      loadingAddAgent: false,
      tableColumns: [
        { key: '_id', label: '#', sortable: true },
        { key: 'reference', label: 'Référence', sortable: true },
        { key: 'createdAt', label: 'Date', sortable: true },
        { key: 'nomEtablissement', label: 'Etablissement', sortable: true },
        { key: 'statut', label: 'Statut', sortable: true },
        { key: 'actions' },
      ],

      isLoadingSendResetPasswordMail: false,
      isLoadingTreatment: false,
      isLoadingSendingOTP: false,
      // dayjs,
      today: null,
      formattedDate: '',

      removeRole: 'removeRole',
      addRole: 'addRole',
      tableDataRole: [],
      excludedRoles: [
        "Utilisateur Externe de l'Administration",
        'Utilisateur Externe Hébergement',
        'Utilisateur Externe Agence de Voyages',
        'Utilisateur Externe Guide de Tourisme',
        'Utilisateur Externe Etablissement de Restauration et Assimilé',
      ],
      new_role_id: null,
      new_role_loading: false,
      remove_role: null,
      remove_role_loading: false,
    }
  },
  computed: {
    etablissementDetails() {
      return (
        this.itemDetails && this.itemDetails && this.itemDetails.etablissement
      )
    },
    connected_user_id() {
      const { currentUserId } = utilsService.currentUserUtils()
      return currentUserId
    },
    user_roles_alias() {
      let roles_alias = []
      this.itemDetails &&
        this.itemDetails.roles.forEach((role) => {
          roles_alias.push(role.alias)
        })
      return roles_alias
    },
    user_roles_ids() {
      let roles_ids = []
      this.itemDetails &&
        this.itemDetails.roles.forEach((role) => {
          roles_ids.push(role.id)
        })
      return roles_ids
    },
  },
  watch: {
    $route: {
      immediate: true,
      handler(val, old) {
        console.log('::: 🔴')
        const { userId } = val.params
        console.log('::: 🔴', userId)
        if (userId) {
          this.isFetchingUserDetails = true
          this.id = userId
          this.loadData()
          this.getListRole()
          if (this.itemDetails) {
          }
        }
      },
    },
  },
  beforeDestroy() {
    unregisterStoreModule(this.requiredStoreModules)
  },

  mounted() {
    this.today = new Date()
    this.formattedDate = this.today.toLocaleString('fr-FR', {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
    })
    this.formattedDate = this.replaceSlashByDash(this.formattedDate)
  },
  methods: {
    ...mapActions('etablissements', {
      getEtablissement: 'getEtablissement',
      getAgent: 'getAgent',
    }),
    ...mapActions('users', {
      action_resetPasswordUser: 'resetPasswordUser',
      action_updateUser: 'updateUser',
      action_getUser: 'getUser',
    }),
    ...mapActions('roles', { action_fetchRoles: 'fetchRoles' }),
    // ------------------------------------------------------
    async getListRole() {
      this.action_fetchRoles()
        .then(async (response) => {
          if (response.status === 200) {
            this.tableDataRole = response.data.data || []
            this.tableDataRole = this.tableDataRole.filter((role) => {
              if (!this.excludedRoles.includes(role.title))
                return !this.user_roles_alias.includes(role.alias)
            })
          }
        })
        .catch((err) => {
          console.log('err::: ', err)
        })
    },
    loadData() {
      this.isLoadingTableData = true
      this.action_getUser(this.id)
        .then((res) => {
          const { data } = res.data || {}
          this.itemDetails = data
          this.isFetchingUserDetails = false
          this.isLoadingTableData = false
        })
        .catch((err) => {
          this.isLoadingTableData = false
          this.isFetchingUserDetails = false
          this.$toast({
            component: ToastificationContent,
            props: {
              title: err.message || 'Une erreur est survenue',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
    updateAccount(attribut, value) {
      if (this.isLoadingTreatment) return
      this.isLoadingTreatment = true
      const params = {
        id: this.itemDetails && this.itemDetails.id,
        data: { [attribut]: value },
      }
      // return;
      this.action_updateUser(params)
        .then(async (res) => {
          if (res.status === 202) {
            this.isLoadingTreatment = false
            this.loadData()
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Opération réussie',
                icon: 'CheckIcon',
                variant: 'success',
              },
            })
          }
        })
        .catch((err) => {
          this.isLoadingTreatment = false
          this.$toast({
            component: ToastificationContent,
            props: {
              title: err.message || 'Une erreur est survenue',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
    send_otp() {
      if (this.isLoadingSendingOTP) return
      this.isLoadingSendingOTP = true

      if (this.itemDetails.email) {
        this.$store
          .dispatch('auth/sendOTP', {
            email: this.itemDetails.email,
          })
          .then((response) => {
            console.log('response::: send_otp', response)
            console.log('response::: ', response)
            if (response.data.data && response.status === 200) {
              this.isLoadingSendingOTP = false
              this.loadData()
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Opération réussie',
                  icon: 'CheckIcon',
                  variant: 'success',
                },
              })
            }
          })
          .catch((error) => {
            this.isLoadingSendingOTP = false
            this.$toast({
              component: ToastificationContent,
              props: {
                title: err.message || 'Une erreur est survenue',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
          })
      }
    },
    sendRecupPasswordMail() {
      this.isLoadingSendResetPasswordMail = true
      this.action_resetPasswordUser({ email: this.itemDetails.email })
        .then(async (res) => {
          // return;
          if (res.status === 200) {
            this.isLoadingSendResetPasswordMail = false
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Opération réussie',
                icon: 'CheckIcon',
                variant: 'success',
              },
            })
          }
        })
        .catch((err) => {
          this.isLoadingSendResetPasswordMail = false
          this.$toast({
            component: ToastificationContent,
            props: {
              title: err.message || 'Une erreur est survenue',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },

    on_add_role() {
      if (this.new_role_loading) return
      this.new_role_loading = true
      let new_roles_list = [...this.user_roles_ids]
      new_roles_list.push(this.new_role_id)
      const params = {
        id: this.itemDetails && this.itemDetails.id,
        data: { role_ids: new_roles_list },
      }
      this.action_updateUser(params)
        .then(async (res) => {
          if (res.status === 202) {
            this.hideAddRole()
            this.new_role_loading = false
            this.new_role_id = null
            this.loadData()
            this.getListRole()
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Opération réussie',
                icon: 'CheckIcon',
                variant: 'success',
              },
            })
          }
        })
        .catch((err) => {
          this.new_role_loading = false
          this.$toast({
            component: ToastificationContent,
            props: {
              title: err.message || 'Une erreur est survenue',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
    on_remove_role() {
      if (this.remove_role_loading) return
      this.remove_role_loading = true
      let remove_roles_list = [...this.user_roles_ids]
      remove_roles_list = remove_roles_list.filter((role_id) => {
        return role_id != this.remove_role.id
      })

      const params = {
        id: this.itemDetails && this.itemDetails.id,
        data: { role_ids: remove_roles_list },
      }
      this.action_updateUser(params)
        .then(async (res) => {
          if (res.status === 202) {
            this.hideRemoveRole()
            this.remove_role_loading = false
            this.loadData()
            this.remove_role = null
            this.getListRole()
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Opération réussie',
                icon: 'CheckIcon',
                variant: 'success',
              },
            })
          }
        })
        .catch((err) => {
          this.remove_role_loading = false
          this.$toast({
            component: ToastificationContent,
            props: {
              title: err.message || 'Une erreur est survenue',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },

    showAddRole() {
      if (this.tableDataRole.length === 0) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "L'utilisateur a déjà tous les rôles",
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
        return
      }
      this.$refs[this.addRole].show()
    },
    hideAddRole() {
      this.$refs[this.addRole].hide()
    },
    showRemoveRole(role_id) {
      if (this.user_roles_ids.length === 1) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "vous ne pouvez pas retirer tous les rôles de l'utilisateur",
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
        return
      }
      this.remove_role = role_id
      this.$refs[this.removeRole].show()
    },
    hideRemoveRole() {
      this.$refs[this.removeRole].hide()
    },
  },
}
</script>

<style lang="scss" scoped>
.img-ctn {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;

  .img {
    height: 100px;
    width: 100px;
    background: rgba(4, 4, 4, 0.583);
    border-radius: 5px;
  }
}

.res {
  @media (max-width: 768px) {
    margin-top: 15px;
  }
}

.addRole:hover {
  cursor: grab/pointer;
}
</style>
